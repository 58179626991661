
export const availabilitySampleResponse = [
  {"availabilityId":1,"employeeId":3,"availabilityDay":1,"availabilityStartTime":1,"availabilityEndTime":1300},
  {"availabilityId":2,"employeeId":24,"availabilityDay":1,"availabilityStartTime":33,"availabilityEndTime":766},
  {"availabilityId":3,"employeeId":37,"availabilityDay":1,"availabilityStartTime":345,"availabilityEndTime":999},
  {"availabilityId":4,"employeeId":3,"availabilityDay":2,"availabilityStartTime":444,"availabilityEndTime":777},
  {"availabilityId":5,"employeeId":24,"availabilityDay":2,"availabilityStartTime":555,"availabilityEndTime":1204},
  {"availabilityId":6,"employeeId":37,"availabilityDay":2,"availabilityStartTime":111,"availabilityEndTime":444},
  {"availabilityId":7,"employeeId":3,"availabilityDay":3,"availabilityStartTime":33,"availabilityEndTime":888},
  {"availabilityId":8,"employeeId":24,"availabilityDay":3,"availabilityStartTime":123,"availabilityEndTime":141},
  {"availabilityId":9,"employeeId":37,"availabilityDay":3,"availabilityStartTime":234,"availabilityEndTime":435},
  {"availabilityId":10,"employeeId":2,"availabilityDay":4,"availabilityStartTime":77,"availabilityEndTime":777},
  {"availabilityId":11,"employeeId":2,"availabilityDay":6,"availabilityStartTime":4,"availabilityEndTime":345},
  {"availabilityId":12,"employeeId":3,"availabilityDay":2,"availabilityStartTime":112,"availabilityEndTime":545},
  {"availabilityId":13,"employeeId":3,"availabilityDay":2,"availabilityStartTime":232,"availabilityEndTime":444},
  {"availabilityId":14,"employeeId":3,"availabilityDay":3,"availabilityStartTime":55,"availabilityEndTime":555}
]
