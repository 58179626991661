import React from "react";
import ReactLoading from "react-loading";
import TimePicker from "react-time-picker";
import styles from "../Shared/Form.module.css";
import plusIcon from "../../img/plus-icon.png";
import { Util } from "../../util";
import { Days } from "../../mocks/daysOfWeek";
import errorIcon from "../../img/error-icon.png";

class AddShiftForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormVisible: false,
      isSubmitting: false,
      startTime: "00:00",
      endTime: "00:00",
      position: "",
      department: "",
      errors: {
        startTime: {
          message: "",
          isValid: true
        },
        endTime: {
          message: "",
          isValid: true
        },
        position: {
          message: "",
          isValid: true
        },
        department: {
          message: "",
          isValid: true
        },
      }
    }

    this.updateStateText = field => e => {
      const newState = Object.assign({}, this.state, { [field]: e.target.value});
      this.setState(newState);
    }

    this.updateStateTime = field => time => {
      const newState = Object.assign({}, this.state, { [field]: time});
      this.setState(newState);
    }

    this.handleClick = e => {
      e.preventDefault();

      this.setState(prevState => {
        return {
          isFormVisible: !prevState.isFormVisible,
          startTime: "00:00",
          endTime: "00:00",
          position: "",
          department: "",
          errors: {
            startTime: {
              message: "",
              isValid: true
            },
            endTime: {
              message: "",
              isValid: true
            },
            position: {
              message: "",
              isValid: true
            },
            department: {
              message: "",
              isValid: true
            },
          }
        }
      })
    }

    this.handleSubmit = e => {
      e.preventDefault();

      this.validateForm()
        .then(isValid => {
          if (isValid) this.setState({ isSubmitting: true}, () => this.submitForm());
        })
    }

    this.submitForm = () => {
      const { startTime, endTime, position, department } = this.state;
      let data = {
        userId: 1,
        shiftDay: this.props.day,
        shiftStartTime: Util.convertTimeStringToMinutes(startTime),
        shiftEndTime: Util.convertTimeStringToMinutes(endTime),
        shiftType: "full",
        shiftPosition: position,
        shiftDepartment: department,
      };

      fetch("https://burgertechnologies.com/timekeeper/shifts/create.php",
        {
          method: "POST",
          body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => {
          if (res.result) {
            data.shiftId = res.id;
            this.props.addNewShift(data);
          }
        })
        .then(() => this.setState({
          isFormVisible: false,
          isSubmitting: false,
          startTime: "00:00",
          endTime: "00:00",
          position: "",
          department: ""
        }))
        .catch(error => console.log(error));
    }

    this.validateForm = async () => {
      let errors = {
        position: {},
        department: {}
      };

      errors.position = this.validateTextField("position");
      errors.department = this.validateTextField("position");

      this.setState({errors: errors});

      return errors.position.isValid && errors.department.isValid
    }

    /**
     * Validate a single text input field.
     * @param field
     * @return {{isValid: boolean, message: string}}
     */
    this.validateTextField = field => {
      if (this.state[field].length < 1) {
        return {
          message: `${Util.capitalizeString(field)} cannot be empty.`,
          isValid: false
        }
      }
      else {
        return {
          message: "",
          isValid: true
        }
      }
    }
  }

  render() {
    return (
      <div className={styles.container}>
        {
          this.state.isFormVisible
            ?
            <div className={styles.wrapper}>
              {
                this.state.isSubmitting ?
                  <ReactLoading type={"bars"} color={"black"} />
                  :
                  <form onSubmit={this.handleSubmit} className={styles.formContainer}>
                    <h2 className={styles.formHeading}>Add New Shift</h2>
                    <hr/>
                    <label className={styles.timeInputLabel}>{Util.capitalizeString(Days[this.props.day])}</label>
                    <div className={styles.timeFieldset}>
                      <div className={styles.timeInputContainerFrom}>
                        <label htmlFor="startTime" className={styles.timeLabel}>From:</label>
                        <TimePicker value={this.state.startTime}
                                    onChange={this.updateStateTime("startTime")}
                                    clearIcon={null}
                                    disableClock={true}
                        />
                      </div>
                      <div className={styles.timeInputContainerTo}>
                        <label htmlFor="endTime" className={styles.timeLabel}>To:</label>
                        <TimePicker value={this.state.endTime}
                                    onChange={this.updateStateTime("endTime")}
                                    clearIcon={null}
                                    disableClock={true}
                        />
                      </div>
                    </div>

                    <div className={styles.fieldContainer}>
                      <label htmlFor="position" className={styles.textInputLabel}>
                        Position
                        <p className={styles.errorText}>{this.state.errors.position.message}</p>
                      </label>
                      <div className={styles.textInputContainer}>
                        <input type="position"
                               name="position"
                               value={this.state.position}
                               onChange={this.updateStateText("position")}
                               className={this.state.errors.position.isValid ? styles.textInput : styles.textInputError}
                        />
                        {
                          !this.state.errors.position.isValid &&
                          <img src={errorIcon} alt="warning-icon" className={styles.errorIcon} />
                        }
                      </div>
                    </div>

                    <div className={styles.fieldContainer}>
                      <label htmlFor="department" className={styles.textInputLabel}>
                        Department
                        <p className={styles.errorText}>{this.state.errors.department.message}</p>
                      </label>
                      <div className={styles.textInputContainer}>
                        <input type="department"
                               name="department"
                               value={this.state.department}
                               onChange={this.updateStateText("department")}
                               className={this.state.errors.department.isValid ? styles.textInput : styles.textInputError}
                        />
                        {
                          !this.state.errors.department.isValid &&
                          <img src={errorIcon} alt="warning-icon" className={styles.errorIcon} />
                        }
                      </div>
                    </div>

                    <input type="submit"
                           value="Add Shift"
                           className={styles.buttonGreen}
                    />
                    <button onClick={this.handleClick} className={styles.buttonOrange}>Cancel</button>
                  </form>
              }
            </div>
            :
            <button onClick={this.handleClick} className={styles.plusButton}>
              <img src={plusIcon} alt="add-shift-icon" className={styles.plusIcon} />
            </button>
        }
      </div>
    )
  }
}

export default AddShiftForm;
