// Dependencies
import React from "react";
// Components
import UpdateAvailabilityForm from "./UpdateAvailabilityForm";
// Styles
import styles from "./Employee.module.css";
import sharedStyles from "../Shared/Form.module.css";

class Employee extends React.Component {
  render() {
    const { employee } = this.props;

    return (
      <section className={styles.container}>
        <h4 className={styles.name}>{employee.employeeName}</h4>
        <ul className={styles.list}>
          <li className={styles.listItem}>Department: {employee.employeeDepartment}</li>
          <li className={styles.listItem}>Position: {employee.employeePosition}</li>
          <li className={styles.listItem}>Pay rate: {employee.employeePayRate}</li>
        </ul>
        <div className={styles.buttonCol}>
          <UpdateAvailabilityForm availability={employee.availability}
                                  updateAvailability={this.props.updateAvailability}
                                  employeeId={employee.employeeId}
                                  name={employee.employeeName}
          />
          <button className={sharedStyles.buttonOrange}
                  onClick={this.props.deleteEmployee(employee)}
          >
            Delete employee
          </button>
        </div>
      </section>
    )
  }
}

export default Employee;
