import React from "react";
import styles from "./index.module.css";
import arrowIcon from "../../img/down-arrow-icon.png";
import { Util } from "../../util";

class EditorNav extends React.Component {
  render() {
    return (
      <header className={styles.dayHeader}>
        <button className={styles.navButton} onClick={this.props.navigatePreviousDay}>
          <img src={arrowIcon} alt="" className={styles.leftArrowIcon} />
        </button>
        <h2 className={styles.navHeading}>{Util.capitalizeString(this.props.day)}</h2>
        <button className={styles.navButton} onClick={this.props.navigateNextDay}>
          <img src={arrowIcon} alt="" className={styles.rightArrowIcon} />
        </button>
      </header>
    )
  }
}

export default EditorNav;
