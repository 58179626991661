import { Days } from "./mocks/daysOfWeek";

export const Util = {
  /**
   * Group an employee with all of their availability
   * @param {Array<Object>} employees - Array of employee response objects
   * @param {Array<Object>} availability - Array of availability response objects
   * @return {Object}
   */
  groupEmployeeWithAvailability: (employees, availability) => {
    let tempHash = {};

    for (let i = 0; i < employees.length; i++) {
      // if employee is not detected in temp hash...
      if (!tempHash.hasOwnProperty(employees[i].employeeId)) {
        // add them with employeeId as key
        tempHash[employees[i].employeeId] = employees[i];
        // then create empty object property to hold availability
        tempHash[employees[i].employeeId].availability = {};
      }
    }

    for (let j = 0; j < availability.length; j++) {
      // check if tempHash has employee from corresponding availability
      if (tempHash.hasOwnProperty(availability[j].employeeId)) {
        // then add the current availability to the employee availability property with the id as key
        tempHash[availability[j].employeeId].availability[availability[j].availabilityId] = availability[j];
      }
    }

    return tempHash
  },

  /**
   * Gather all employees that have corresponding availability to a given shift.
   * @param {Object} shift - A single work shift.
   * @param {Object} employees - All employees under user account.
   * @return {Object}
   */
  determineEmployeesForShift: (shift, employees) => {
    let applicableEmployees = {};

    // loop over all employees
    for (const employeeId of Object.keys(employees)) {

      // loop over current employee's availability
      for (const availabilityId of Object.keys(employees[employeeId].availability)) {

        const availability = employees[employeeId].availability[availabilityId];

        if (
          // Check shift compatibility with current employee availability
          shift.shiftStartTime >= availability.availabilityStartTime && // shift start is within avail start
          shift.shiftEndTime <= availability.availabilityEndTime && // shift end is within avail end
          availability.availabilityDay === shift.shiftDay // Day check
        ) {
          applicableEmployees[employees[employeeId].employeeId] = employees[employeeId];
        }
      }
    }

    return applicableEmployees
  },

  /**
   * Convert time string to count of minutes
   * @param {string} time - using format "hh:mm"
   * @return {number}
   */
  convertTimeStringToMinutes: time => {
    let total = 0;
    const [ hour, min ] = time.split(":");

    total += parseInt(hour) * 60; // add hours
    total += parseInt(min); // add minutes

    return total
  },

  /**
   * Converts minutes to string using format "hh:mm"
   * @param {number} mins
   * @return {string}
   */
  convertMinutesToTimeString: mins => {
    let hour = Math.floor(mins / 60).toString();
    if (hour.length < 2) hour = "0" + hour; // add leading zero to hour if single digit

    let min = (mins % 60).toString();
    if (min.length < 2) min = "0" + min; // add leading zero to min if single digit

    return `${hour}:${min}`
  },

  /**
   * Convert and format minutes into time string for display.
   * @param {number} mins
   * @return {Array<string>}
   */
  convertMinutesToDisplayTime: mins => {
    let period = "AM";
    let hour = Math.floor(mins / 60);
    let min = (mins % 60).toString();

    if (hour > 11) period = "PM";
    if (hour === 0 || hour - 12 === 0) hour = 12; // Prevent 12 o'clock displaying as 0
    if (hour > 12) hour -= 12;
    if (min.length < 2) min = "0" + min; // add leading zero to min if single digit

    return [`${hour}:${min}`, period]
  },

  /**
   * Convert minutes integer into a string for display. X hrs X mins format.
   * @param mins
   * @return {string}
   */
  displayDuration: mins => {
    if (mins < 0) return "0 hrs 0 mins"
    else {
      const hour = Math.floor(mins / 60)
      let min = (mins % 60).toString();

      if (min.length < 2) min = "0" + min; // add leading zero to min if single digit

      return `${hour} hrs ${min} mins`
    }
  },

  /**
   * Capitalize first character of given string.
   * @param {string} string
   */
  capitalizeString: string => {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
  },

  /**
   * Sort shifts into separate arrays depending on day of week.
   * @param {Array<Object>} shifts
   * @return {{sunday: {}, saturday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, monday: {}}}
   */
  sortShiftsByDay: shifts => {
    let tempHash = {
      monday: {},
      tuesday: {},
      wednesday: {},
      thursday: {},
      friday: {},
      saturday: {},
      sunday: {}
    };

    for (let i = 0; i < shifts.length; i++) {
      switch (Days[shifts[i].shiftDay]) {
        case "monday":
          tempHash.monday[shifts[i].shiftId] = shifts[i];
          break;
        case "tuesday":
          tempHash.tuesday[shifts[i].shiftId] = shifts[i];
          break;
        case "wednesday":
          tempHash.wednesday[shifts[i].shiftId] = shifts[i];
          break;
        case "thursday":
          tempHash.thursday[shifts[i].shiftId] = shifts[i];
          break;
        case "friday":
          tempHash.friday[shifts[i].shiftId] = shifts[i];
          break;
        case "saturday":
          tempHash.saturday[shifts[i].shiftId] = shifts[i];
          break;
        case "sunday":
          tempHash.sunday[shifts[i].shiftId] = shifts[i];
          break;
        default:
          console.log(`Error: iter:${i} -> sortShiftsByDay -> Shifts: ${shifts[i].shiftId}`)
      }
    }

    return tempHash
  },

  /**
   * Sort employees into separate arrays depending on their availability day.
   * @param {Object} employees - Keys = employee ID, properties = single employee.
   * @return {{sunday: {}, saturday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, monday: {}}}
   */
  sortEmployeesByDayAvail: employees => {
    let tempHash = {
      monday: {},
      tuesday: {},
      wednesday: {},
      thursday: {},
      friday: {},
      saturday: {},
      sunday: {}
    };

    // Loop over employees
    for (const employeeId of Object.keys(employees)) {
      // Loop over current employees availability
      for (const availId of Object.keys(employees[employeeId].availability)) {
        switch (Days[employees[employeeId].availability[availId].availabilityDay]) {
          case "monday":
            tempHash.monday[employees[employeeId].employeeId] = employees[employeeId];
            break;
          case "tuesday":
            tempHash.tuesday[employees[employeeId].employeeId] = employees[employeeId];
            break;
          case "wednesday":
            tempHash.wednesday[employees[employeeId].employeeId] = employees[employeeId];
            break;
          case "thursday":
            tempHash.thursday[employees[employeeId].employeeId] = employees[employeeId];
            break;
          case "friday":
            tempHash.friday[employees[employeeId].employeeId] = employees[employeeId];
            break;
          case "saturday":
            tempHash.saturday[employees[employeeId].employeeId] = employees[employeeId];
            break;
          case "sunday":
            tempHash.sunday[employees[employeeId].employeeId] = employees[employeeId];
            break;
          default:
            console.log(`Error: employee -> ${employees[employeeId].employeeId}.`)
        }
      }
    }

    return tempHash
  },
}