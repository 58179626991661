import React from "react";
import styles from "../Shared/Form.module.css";
import ReactLoading from "react-loading";
import plusIcon from "../../img/plus-icon.png";
import { Util } from "../../util";
import errorIcon from "../../img/error-icon.png";

class AddEmployeeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormVisible: false,
      isSubmitting: false,
      name: "",
      department: "",
      position: "",
      type: "",
      payRate: "",
      errors: {
        name: {
          message: "",
          isValid: true
        },
        department: {
          message: "",
          isValid: true
        },
        position: {
          message: "",
          isValid: true
        },
        type: {
          message: "",
          isValid: true
        },
        payRate: {
          message: "",
          isValid: true
        }
      }
    }

    this.updateState = field => e => {
      const newState = Object.assign({}, this.state, { [field]: e.target.value});
      this.setState(newState);
    }

    this.handleClick = e => {
      e.preventDefault();
      this.setState(prevState => {
        return {
          isFormVisible: !prevState.isFormVisible,
          name: "",
          department: "",
          position: "",
          type: "",
          payRate: "",
          errors: {
            name: {
              message: "",
              isValid: true
            },
            department: {
              message: "",
              isValid: true
            },
            position: {
              message: "",
              isValid: true
            },
            type: {
              message: "",
              isValid: true
            },
            payRate: {
              message: "",
              isValid: true
            }
          }
        }
      });
    }

    /**
     * Post init availability for all days of week for new employee.
     * @param {number} employeeId
     * return {Promise<Array<Object>>} availability - Array of init availability objects to be set to new employee
     */
    this.postNewAvailability = employeeId => {
      let promises = [];

      // Loop over all days of week, 1 - 7 do not start at zero
      for (let i = 1; i < 8; i++) {
        let dayAvail = {
          employeeId: employeeId,
          availabilityDay: i,
          availabilityStartTime: 0,
          availabilityEndTime: 0
        }

        const res = fetch("https://burgertechnologies.com/timekeeper/availability/create.php", {
          method: "POST",
          body: JSON.stringify(dayAvail)
        }).then(res => res.json())

        promises.push(res);
      }

      return Promise.all(promises)
        .then(responses => {
          let availability = {};

          // Loop over responses
          for (let i = 0; i < responses.length; i++) {
            availability[responses[i].id] = {
              employeeId: employeeId,
              availabilityDay: i + 1, // prevent off by one error
              availabilityStartTime: 0,
              availabilityEndTime: 0,
              availabilityId: responses[i].id
            }
          }

          return availability
        })
        .catch(error => console.log(error))
    }

    /**
     * Post request for new employee to database.
     */
    this.postNewEmployee = () => {
      let data = {
        userId: 1, // this.props.user.userId
        employeeName: this.state.name,
        employeePosition: 2, // this.state.position
        employeeDepartment: 2, // this.state.department
        employmentType: 2, // this.state.type
        employeePayRate: parseFloat(this.state.payRate)
      };

      fetch("https://burgertechnologies.com/timekeeper/employees/create.php", {
        method: "POST",
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(res => {
          if (res.result) {
            data.employeeId = res.id;
            return this.postNewAvailability(res.id);
          }
        })
        .then(avail => {
          data.availability = avail;
          this.props.addNewEmployee(data);
        })
        .then(() => this.setState({
            isFormVisible: false,
            isSubmitting: false,
            name: "",
            department: "",
            position: "",
            type: "",
            payRate: ""
          }))
        .catch(error => console.log(error));
    }

    this.validateForm = async () => {
      let errors = {
        name: {},
        position: {},
        department: {},
        type: {},
        payRate: {}
      };

      // Check text fields
      errors.name = this.validateTextField("name");
      errors.position = this.validateTextField("position");
      errors.department = this.validateTextField("department");
      errors.type = this.validateTextField("type");
      errors.payRate = this.validateTextField("payRate");

      this.setState({errors: errors});

      return errors.name.isValid && errors.position.isValid && errors.department.isValid && errors.type.isValid &&
        errors.payRate.isValid
    }

    /**
     * Validate a single text input field.
     * @param field
     * @return {{isValid: boolean, message: string}}
     */
    this.validateTextField = field => {
      if (this.state[field].length < 1) {
        return {
          message: `${Util.capitalizeString(field)} cannot be empty.`,
          isValid: false
        }
      }
      else {
        return {
          message: "",
          isValid: true
        }
      }
    }

    this.handleSubmit = e => {
      e.preventDefault();

      this.validateForm()
        .then(isValid => {
          if (isValid) this.setState({ isSubmitting: true}, () => this.postNewEmployee());
        })
    }
  }

  render() {
    return (
      <div className={styles.container}>
        {
          this.state.isFormVisible &&
            <div className={styles.wrapper}>
              {
                this.state.isSubmitting ?
                  <ReactLoading type={"bars"} color={"black"} />
                  :
                  <form onSubmit={this.handleSubmit} className={styles.formContainer}>
                    <h2 className={styles.formHeading}>Add Employee</h2>
                    <hr/>

                    <div className={styles.fieldContainer}>
                      <label htmlFor="name" className={styles.textInputLabel}>
                        Name
                        <p className={styles.errorText}>{this.state.errors.name.message}</p>
                      </label>
                      <div className={styles.textInputContainer}>
                        <input type="text"
                               name="name"
                               value={this.state.name}
                               className={this.state.errors.name.isValid ? styles.textInput : styles.textInputError}
                               onChange={this.updateState("name")}
                        />
                        {
                          !this.state.errors.name.isValid &&
                          <img src={errorIcon} alt="warning-icon" className={styles.errorIcon} />
                        }
                      </div>
                    </div>

                    <div className={styles.fieldContainer}>
                      <label htmlFor="department" className={styles.textInputLabel}>
                        Department
                        <p className={styles.errorText}>{this.state.errors.department.message}</p>
                      </label>
                      <div className={styles.textInputContainer}>
                        <input type="text"
                               name="department"
                               value={this.state.department}
                               className={this.state.errors.department.isValid ? styles.textInput : styles.textInputError}
                               onChange={this.updateState("department")}
                        />
                        {
                          !this.state.errors.department.isValid &&
                          <img src={errorIcon} alt="warning-icon" className={styles.errorIcon} />
                        }
                      </div>
                    </div>

                    <div className={styles.fieldContainer}>
                      <label htmlFor="position" className={styles.textInputLabel}>
                        Position
                        <p className={styles.errorText}>{this.state.errors.position.message}</p>
                      </label>
                      <div className={styles.textInputContainer}>
                        <input type="text"
                               name="position"
                               value={this.state.position}
                               className={this.state.errors.position.isValid ? styles.textInput : styles.textInputError}
                               onChange={this.updateState("position")}
                        />
                        {
                          !this.state.errors.position.isValid &&
                          <img src={errorIcon} alt="warning-icon" className={styles.errorIcon} />
                        }
                      </div>
                    </div>

                    <div className={styles.fieldContainer}>
                      <label htmlFor="type" className={styles.textInputLabel}>
                        Type
                        <p className={styles.errorText}>{this.state.errors.type.message}</p>
                      </label>
                      <div className={styles.textInputContainer}>
                        <input type="text"
                               name="type"
                               value={this.state.type}
                               className={this.state.errors.type.isValid ? styles.textInput : styles.textInputError}
                               onChange={this.updateState("type")}
                        />
                        {
                          !this.state.errors.type.isValid &&
                          <img src={errorIcon} alt="warning-icon" className={styles.errorIcon} />
                        }
                      </div>
                    </div>

                    <div className={styles.fieldContainer}>
                      <label htmlFor="payRate" className={styles.textInputLabel}>
                        Pay Rate
                        <p className={styles.errorText}>{this.state.errors.payRate.message}</p>
                      </label>
                      <div className={styles.textInputContainer}>
                        <input type="text"
                               name="payRate"
                               value={this.state.payRate}
                               className={this.state.errors.payRate.isValid ? styles.textInput : styles.textInputError}
                               onChange={this.updateState("payRate")}
                        />
                        {
                          !this.state.errors.payRate.isValid &&
                          <img src={errorIcon} alt="warning-icon" className={styles.errorIcon} />
                        }
                      </div>
                    </div>

                    <input type="submit"
                           value="Add Employee"
                             className={styles.buttonGreen}
                    />
                    <button onClick={this.handleClick} className={styles.buttonOrange}>Cancel</button>
                  </form>
              }
            </div>
        }
        <button onClick={this.handleClick} className={styles.plusButton}>
          <img src={plusIcon} alt="add-shift-icon" className={styles.plusIcon} />
        </button>
      </div>
    )
  }
}

export default AddEmployeeForm;
