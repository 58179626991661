import React from "react";
import { Link } from "react-router-dom";
import styles from "./Nav.module.css";

class Nav extends React.Component {
  render() {
    const { handleNavLinkClick, isLoggedIn, logOut } = this.props;

    return (
      <nav className={styles.navMenu}>
        <Link to={"/"}
              className={styles.navMenuLink}
              onClick={handleNavLinkClick}
        >
          Home
        </Link>
        <Link to={"/employees"}
              className={styles.navMenuLink}
              onClick={handleNavLinkClick}
        >
          Employees
        </Link>

        <Link to={"/schedule"}
              className={styles.navMenuLink}
              onClick={handleNavLinkClick}
        >
          Schedule
        </Link>
        {
          isLoggedIn ?
            <div className={styles.logInSignUpButtons}>
              <Link to={"/"}
                    className={styles.navMenuLink}
                    onClick={logOut}
              >
                Log out
              </Link>
            </div>
            :
            <div className={styles.logInSignUpButtons}>
              <Link to={"/login"}
                    className={styles.navMenuLink}
                    onClick={handleNavLinkClick}
              >
                Log in
              </Link>
              <span className={styles.lineDivider}>|</span>
              <Link to={"/signup"}
                    className={styles.navMenuLink}
                    onClick={handleNavLinkClick}
              >
                Sign up
              </Link>
            </div>
        }
        <footer className={styles.footer}>
          <p className={styles.footerRow}>&copy; 2021 Burger Technologies All rights reserved</p>
          <p className={styles.footerRow}>Created by jodyanna</p>
          <p className={styles.footerRow}>Follow me on <a href="https://github.com/jodyanna">GitHub</a>!</p>
        </footer>
      </nav>

    )
  }
}


export default Nav;
