import React from "react";
import { Redirect } from "react-router-dom";
import LogInForm from "./LogInForm";
import styles from "./index.module.css"
import userContext from "../../context/userContext";

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToHome: false
    }

    this.triggerRedirect = () => this.setState({ redirectToHome: true})
  }


  render() {
    if (this.state.redirectToHome) return <Redirect to="/" />

    return (
      <userContext.Consumer>
        {
          ({ logIn }) => {
            return (
              <div className={styles.container}>
                <LogInForm logIn={logIn}
                           triggerRedirect={this.triggerRedirect}
                />
              </div>
            )
          }
        }
      </userContext.Consumer>
    )
  }
}

export default LogIn;
