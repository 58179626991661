import React from "react";
import formStyles from "../Shared/Form.module.css";
import styles from "./SignUpForm.module.css";
import errorIcon from "../../img/error-icon.png";
import { Link } from "react-router-dom";
import { Util } from "../../util";

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      errors: {
        username: {
          message: "",
          isValid: true
        },
        email: {
          message: "",
          isValid: true
        },
        password: {
          message: "",
          isValid: true
        }
      }
    };

    this.handleUsernameChange = e => this.setState({username: e.target.value})
    this.handleEmailChange = e => this.setState({email: e.target.value})
    this.handlePasswordChange = e => this.setState({password: e.target.value})

    this.handleSubmit = e => {
      e.preventDefault();

      this.validateForm()
        .then(isValid => {
          if (isValid) {
            let data = {
              "userName": this.state.username,
              "password": this.state.password,
              "userEmail": this.state.email,
            }

            fetch("https://burgertechnologies.com/timekeeper/users/create.php", {
              method: "POST",
              body: JSON.stringify(data)
            }).then(res => res.json())
              .then(res => {
                if (res.result) {
                  data.userId = res.id;
                  delete data.password;

                  this.props.logIn(data);
                  this.props.triggerRedirect();
                }
              })
              .catch(error => console.log(error));
          }
        })
    }

    this.validateForm = async () => {
      let errors = {
        username: {},
        email: {},
        password: {}
      }

      errors.username = this.validateTextField("username");
      errors.email = this.validateTextField("email");
      errors.password = this.validateTextField("password");

      this.setState({errors: errors});

      return errors.username.isValid && errors.email.isValid && errors.password.isValid
    }

    /**
     * Validate a single text input field.
     * @param field
     * @return {{isValid: boolean, message: string}}
     */
    this.validateTextField = field => {
      if (this.state[field].length < 1) {
        return {
          message: `${Util.capitalizeString(field)} cannot be empty.`,
          isValid: false
        }
      }
      else {
        return {
          message: "",
          isValid: true
        }
      }
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <form onSubmit={this.handleSubmit} className={formStyles.formContainer}>

          <div className={formStyles.fieldContainer}>
            <label htmlFor="username" className={formStyles.textInputLabel}>
              Username
              <p className={formStyles.errorText}>{this.state.errors.username.message}</p>
            </label>
            <div className={formStyles.textInputContainer}>
              <input type="text"
                     name="username"
                     value={this.state.username}
                     className={this.state.errors.username.isValid ? formStyles.textInput : formStyles.textInputError}
                     onChange={this.handleUsernameChange}
              />
              {
                !this.state.errors.username.isValid &&
                <img src={errorIcon} alt="warning-icon" className={formStyles.errorIcon} />
              }
            </div>
          </div>

          <div className={formStyles.fieldContainer}>
            <label htmlFor="email" className={formStyles.textInputLabel}>
              Email
              <p className={formStyles.errorText}>{this.state.errors.email.message}</p>
            </label>
            <div className={formStyles.textInputContainer}>
              <input type="email"
                     name="email"
                     value={this.state.email}
                     className={this.state.errors.email.isValid ? formStyles.textInput : formStyles.textInputError}
                     onChange={this.handleEmailChange}
              />
              {
                !this.state.errors.email.isValid &&
                <img src={errorIcon} alt="warning-icon" className={formStyles.errorIcon} />
              }
            </div>
          </div>

          <div className={formStyles.fieldContainer}>
            <label htmlFor="password" className={formStyles.textInputLabel}>
              Password
              <p className={formStyles.errorText}>{this.state.errors.password.message}</p>
            </label>
            <div className={formStyles.textInputContainer}>
              <input type="password"
                     name="password"
                     value={this.state.password}
                     className={this.state.errors.password.isValid ? formStyles.textInput : formStyles.textInputError}
                     onChange={this.handlePasswordChange}
              />
              {
                !this.state.errors.password.isValid &&
                <img src={errorIcon} alt="warning-icon" className={formStyles.errorIcon} />
              }
            </div>
          </div>

          <input type="submit"
                 value="Sign up"
                 className={formStyles.buttonGreen}
          />
          <div className={styles.prompt}>
            Already have an account? <Link to={"/login"}>Log in</Link>
          </div>
        </form>
      </div>
    )
  }
}

export default SignUpForm;
