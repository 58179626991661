// Dependencies
import React from "react";
// Components
import Employee from "./Employee";
import AddEmployeeForm from "./AddEmployeeForm";
import ReactLoading from "react-loading";
// Styles
import styles from "./index.module.css";
import { Util } from "../../util";
// Context
import userContext from "../../context/userContext";
// Data mocks
import { employeeSampleResponse } from "../../mocks/employeesSampleResponse";
import { availabilitySampleResponse } from "../../mocks/availabilitySampleResponse";

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: {
        availability: {}
      },
      isFetching: true,
    };

    /**
     * Add a new employee to state.
     * @param employee
     */
    this.addNewEmployee = employee => this.setState(prevState => {
      return {
        employees: {
          ...prevState.employees,
          [employee.employeeId]: employee
        }
      }
    });

    /**
     * Perform delete request to backend and remove employee from state.
     * @param employee
     * @return {function(...[*]=)}
     */
    this.deleteEmployee = employee => e => {
      e.preventDefault();

      fetch("https://burgertechnologies.com/timekeeper/employees/delete.php", {
        method: "DELETE",
        body: JSON.stringify({ id: employee.employeeId})
      }).then(res => res.json())
        .then(res => {
          if (res.result) {
            this.setState(prevState => {
              // remove employee from state
              let newEmployees = Object.assign({}, prevState.employees);
              delete newEmployees[employee.employeeId];

              return {
                employees: newEmployees
              }
            });
          }
        })
        .catch(error => console.log(error));
    }

    /**
     * Updates an employee's availability field in state.
     * @param employeeId
     * @return {function(*=): function(*=): function(*): void}
     */
    this.updateAvailability = employeeId => availabilityId => field => time => this.setState(prevState => {
      return {
        employees: {
          ...prevState.employees,
          [employeeId]: {
            ...prevState.employees[employeeId],
            availability: {
              ...prevState.employees[employeeId].availability,
              [availabilityId]: {
                ...prevState.employees[employeeId].availability[availabilityId],
                [field]: Util.convertTimeStringToMinutes(time)
              }
            },
          }
        }
      }
    })

    /**
     * Sorts employees by ID, oldest to newest
     * @param employees
     * @return {*[]}
     */
    this.sortEmployeesById = employees => {
      const employeesArray = Object.keys(employees).map(employeeId => employees[employeeId])

      return employeesArray.sort((a, b) => a.employeeId - b.employeeId)
    }

    // Fetch requests
    this.fetchEmployees = () => fetch("https://burgertechnologies.com/timekeeper/employees/read.php")
        .then(res => employeeSampleResponse) // DEV PARAMETER HERE!
        .catch(err => console.log(err))

    this.fetchAvailability = () => fetch("https://burgertechnologies.com/timekeeper/availability/read.php")
        .then(res => availabilitySampleResponse) // DEV PARAMETER HERE!
        .catch(err => console.log(err))
  }

  componentDidMount() {
    Promise.all([this.fetchEmployees(), this.fetchAvailability()])
      .then(([employees, availability]) => {

        this.setState({
          employees: Util.groupEmployeeWithAvailability(employees, availability),
          isFetching: false
        })
      })
      .catch(error => console.log(error))

  }

  render() {
    return (
      <userContext.Consumer>
        {
          ({ user, isLoggedIn }) => {
            return (
              <div className={styles.container}>
                {
                  this.state.isFetching ?
                    <div className={styles.loadingContainer}>
                      <ReactLoading type={"bars"} color={"black"} />
                    </div>
                    :
                    <div className={styles.employeesContainer}>
                      {
                        Object.keys(this.state.employees).length === 0 ?
                          <div className={styles.container}>
                            <p>There are no employees</p>
                            <p>Add an employee using the button below</p>
                          </div>
                          :
                          this.sortEmployeesById(this.state.employees)
                            .map(employee => <Employee key={employee.employeeId}
                                                       employee={employee}
                                                       updateAvailability={this.updateAvailability}
                                                       deleteEmployee={this.deleteEmployee}
                            />)
                      }
                    </div>
                }
                <AddEmployeeForm user={user}
                                 addNewEmployee={this.addNewEmployee}
                />
              </div>
            )
          }
        }
      </userContext.Consumer>
    )
  }
}

export default Employees;
