import React from "react";
import styles from "./Day.module.css";
import Shift from "./Shift";
import AddShiftForm from "./AddShiftForm";

class Day extends React.Component {
  constructor(props) {
    super(props);

    this.sortShifts = shifts => {
      // Array of just shift object values
      const shiftsArray = Object.keys(shifts).map(shiftId => shifts[shiftId]);

      return shiftsArray.sort((a, b) => a.shiftStartTime - b.shiftStartTime)
    }
  }

  render() {
    return (
      <section className={styles.container}>
        <div className={styles.errorMessage}>
          {this.props.errors.map((error, index) => <span key={index}>Warning: {error}</span>)}
        </div>
        <div className={styles.shiftsContainer}>
          {
            Object.keys(this.props.shifts).length === 0 ?
              <div className={styles.container}>
                <p>There are no shifts today</p>
                <p>Add a shift using the button below</p>
              </div>
              :
              this.sortShifts(this.props.shifts)
                .map(shift =>
                  <Shift key={shift.shiftId}
                         shift={shift}
                         shiftState={this.props.allShiftStates[shift.shiftId]}
                         updateShiftState={this.props.updateShiftState}
                         deleteShift={this.props.deleteShift}
                  />)
          }
        </div>
        <AddShiftForm day={this.props.day}
                      addNewShift={this.props.addNewShift}
        />
      </section>
    )
  }
}

export default Day;
