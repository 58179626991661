import React from "react";
import styles from "./Schedule.module.css";
import { Util } from "../../util";

class Schedule extends React.Component {
  constructor(props) {
    super(props);

    /**
     * Returns jsx for rendering schedule table row. Ergo: creates a table row with an employee's week schedule.
     * @param employee
     * @return {*}
     */
    this.renderTableRow = employee => {
      const name = employee.name;
      let shiftTimes = [];

      for (const day of Object.keys(employee.shifts)) {
        if (employee.shifts[day].startTime !== null && employee.shifts[day].endTime !== null) {
          shiftTimes.push([
            Util.convertMinutesToDisplayTime(employee.shifts[day].startTime),
            Util.convertMinutesToDisplayTime(employee.shifts[day].endTime)
          ])
        }
        else shiftTimes.push([[""], [""]])
      }

      return (
        <tr className={styles.tableRow}>
          <td className={styles.tableCell}>{name}</td>
          {
            shiftTimes.map(times => {
              const [ startTime, startPeriod ] = times[0];
              const [ endTime, endPeriod ] = times[1];

              return (
                <td className={styles.tableCell}>
                  {startTime}<sub>{startPeriod}</sub>
                  <span>&nbsp;-&nbsp;</span>
                  {endTime}<sub>{endPeriod}</sub>
                </td>
              )
            })
          }
        </tr>
      )
    }
  }

  render() {
    const { employees } = this.props;

    return (
      <div className={styles.usLetterSizePage} id="pdf-0">
        <table className={styles.table}>
          <caption>{this.props.caption}</caption>
          <thead>
            <tr className={styles.tableRow}>
              <th className={styles.tableCell}>Employee</th>
              <th className={styles.tableCell}>Monday</th>
              <th className={styles.tableCell}>Tuesday</th>
              <th className={styles.tableCell}>Wednesday</th>
              <th className={styles.tableCell}>Thursday</th>
              <th className={styles.tableCell}>Friday</th>
              <th className={styles.tableCell}>Saturday</th>
              <th className={styles.tableCell}>Sunday</th>
            </tr>
          </thead>
          <tbody>
          {
            Object.keys(employees).map(id => this.renderTableRow(employees[id]))
          }
          </tbody>
        </table>
      </div>
    )
  }
}

export default Schedule;
