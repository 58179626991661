// Dependencies
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// Components
import Header from "./components/Header";
import Home from "./components/Home";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import Employees from "./components/Employees";
import ScheduleEditor from "./components/ScheduleEditor";
// Context
import userContext from "./context/userContext";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        userId: null,
        userName: "",
        userEmail: "",
        createDate: "",
        modDate: "",
        deleteDate: null
      },
      isLoggedIn: false
    }

    this.logIn = user => this.setState({user: user, isLoggedIn: true})
    this.logOut = () => this.setState({user: {}, isLoggedIn: false})
  }

  render() {
    const value = {
      user: this.state.user,
      isLoggedIn: this.state.isLoggedIn,
      logIn: this.logIn,
      logOut: this.logOut,
    }

    return (
      <userContext.Provider value={value} >
        <Router>
          <Header />
          <div className="page">
            <Switch>
              <Route path="/schedule" component={ScheduleEditor} />
              <Route path="/employees" component={Employees} />
              <Route path="/signup" component={SignUp} />
              <Route path="/login" component={LogIn} />
              <Route exact path="/" component={Home} />
            </Switch>
          </div>
        </Router>
      </userContext.Provider>
    );
  }
}

export default App;
