import React from "react";
import styles from "./LogInForm.module.css";
import formStyles from "../Shared/Form.module.css";
import errorIcon from "../../img/error-icon.png";
import { Link } from "react-router-dom";
import { Util } from "../../util";

class LogInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {
        email: {
          message: "",
          isValid: true
        },
        password: {
          message: "",
          isValid: true
        }
      }
    };

    this.handleEmailChange = e => this.setState({email: e.target.value})
    this.handlePasswordChange = e => this.setState({password: e.target.value})

    this.handleSubmit = e => {
      e.preventDefault();

      this.validateForm()
        .then(isValid => {
          if (isValid) {
            fetch("https://www.burgertechnologies.com/timekeeper/users/read.php?password=testing&email=testing1@test.com",
              {
                method: 'GET'
              })
              .then(res => res.json())
              .then(res => {
                this.props.logIn(res);
                this.props.triggerRedirect();
              })
              .catch(err => console.log(err));
          }
        })
    }

    this.validateForm = async () => {
      let errors = {
        email: {},
        password: {}
      };

      errors.email = this.validateTextField("email");
      errors.password = this.validateTextField("password");

      this.setState({errors: errors});

      return errors.email.isValid && errors.password.isValid
    }

    /**
     * Validate a single text input field.
     * @param field
     * @return {{isValid: boolean, message: string}}
     */
    this.validateTextField = field => {
      if (this.state[field].length < 1) {
        return {
          message: `${Util.capitalizeString(field)} cannot be empty.`,
          isValid: false
        }
      }
      else {
        return {
          message: "",
          isValid: true
        }
      }
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <form onSubmit={this.handleSubmit} className={formStyles.formContainer}>
          <div className={formStyles.fieldContainer}>
            <label htmlFor="email" className={formStyles.textInputLabel}>
              Email
              <p className={formStyles.errorText}>{this.state.errors.email.message}</p>
            </label>
            <div className={formStyles.textInputContainer}>
              <input type="email"
                     name="email"
                     value={this.state.email}
                     onChange={this.handleEmailChange}
                     className={this.state.errors.email.isValid ? formStyles.textInput : formStyles.textInputError}
              />
              {
                !this.state.errors.email.isValid &&
                <img src={errorIcon} alt="warning-icon" className={formStyles.errorIcon} />
              }
            </div>
          </div>
          <div className={formStyles.fieldContainer}>
            <label htmlFor="password" className={formStyles.textInputLabel}>
              Password
              <p className={formStyles.errorText}>{this.state.errors.password.message}</p>
            </label>
            <div className={formStyles.textInputContainer}>
              <input type="password"
                     name="password"
                     value={this.state.password}
                     onChange={this.handlePasswordChange}
                     className={this.state.errors.password.isValid ? formStyles.textInput : formStyles.textInputError}
              />
              {
                !this.state.errors.email.isValid &&
                <img src={errorIcon} alt="warning-icon" className={formStyles.errorIcon} />
              }
            </div>
          </div>
          <input type="submit"
                 value="Log in"
                 className={formStyles.buttonGreen}
          />
          <div className={styles.prompt}>
            Don't have an account? <Link to={"/signup"}>Sign up</Link>
          </div>
        </form>
      </div>
    )
  }
}

export default LogInForm;
