import React from "react";
import styles from "./Overview.module.css";
import { Util } from "../../util";
import cancelIcon from "../../img/x-icon.png";
import TextButton from "../Shared/TextButton";

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.totalEmployeeHoursForWeek = employee => {
      let totalHours = 0;

      // Loop over days
      for (const day of Object.keys(employee.shifts)) {
        // Ignore null values
        if (employee.shifts[day].startTime === null || employee.shifts[day].endTime === null) continue

        totalHours += employee.shifts[day].duration;
      }

      return totalHours
    }

    this.totalShiftHoursForWeek = allShifts => {
      let totalHours = 0;

      for (let i = 0; i < allShifts.length; i++) {
        totalHours += allShifts[i].shiftEndTime - allShifts[i].shiftStartTime;
      }

      return Util.displayDuration(totalHours)
    }
  }

  render() {
    const { employees } = this.props;

    return (
      <section className={styles.container}>
        <header className={styles.header}>
          <h2>Schedule Summary</h2>
          <div style={{margin: "0 0 0 auto"}}>
            <TextButton onClick={this.props.handleClick}>
              <img src={cancelIcon} alt="cancel-icon" />
            </TextButton>
          </div>
        </header>

        <h2 className={styles.heading}>Shifts Summary</h2>
        <hr className={styles.lineBreak} />
        <div className={styles.section}>
          <div className={styles.row}>
            Total:
            <span className={styles.rightAlign}>
              {this.props.allShifts.length}
            </span>
          </div>

          <div className={styles.row}>
            Total Duration:
            <span className={styles.rightAlign}>
              {this.totalShiftHoursForWeek(this.props.allShifts)}
            </span>
          </div>

        </div>

        <h2 className={styles.heading}>Employee Hour Total</h2>
        <hr className={styles.lineBreak} />
        {
          Object.keys(employees).length === 0 ?
            <div className={styles.row}>
              No employees are currently scheduled.
            </div>
            :
            Object.keys(employees).map(id => {
              return (
                <div key={id} className={styles.row}>
                  {employees[id].name}
                  <span className={styles.rightAlign}>
                    {Util.displayDuration(this.totalEmployeeHoursForWeek(employees[id]))}
                  </span>
                </div>
              )
            })
        }
      </section>
    )
  }
}

export default Overview;
