import React from "react";

function TextButton(props) {
  return (
    <button onClick={props.onClick}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "8px 4px",
              padding: "4px",
              color: props.color,
              fontWeight: "bold",
              fontSize: "14px",
              backgroundColor: "inherit",
              border: "none",
              cursor: "pointer",
            }}
    >
      {props.children}
    </button>
  )
}

export default TextButton;
