
export const shiftsSampleResponse = [
  {"shiftId":1,"userId":1,"shiftDay":1,"shiftStartTime":500,"shiftEndTime":700,"shiftType":1,"shiftPosition":"Cook","shiftDepartment":"Kitchen"},
  {"shiftId":2,"userId":1,"shiftDay":1,"shiftStartTime":400,"shiftEndTime":800,"shiftType":1,"shiftPosition":"Cook","shiftDepartment":"Kitchen"},
  {"shiftId":33,"userId":1,"shiftDay":1,"shiftStartTime":700,"shiftEndTime":730,"shiftType":1,"shiftPosition":"Cook","shiftDepartment":"Kitchen"},
  {"shiftId":444,"userId":1,"shiftDay":4,"shiftStartTime":500,"shiftEndTime":900,"shiftType":1,"shiftPosition":"Cook","shiftDepartment":"Kitchen"},
  {"shiftId":5,"userId":1,"shiftDay":5,"shiftStartTime":1,"shiftEndTime":800,"shiftType":1,"shiftPosition":"Cook","shiftDepartment":"kitchen"},
  {"shiftId":60,"userId":1,"shiftDay":6,"shiftStartTime":1,"shiftEndTime":700,"shiftType":1,"shiftPosition":"Janitor","shiftDepartment":"Staff"},
  {"shiftId":8,"userId":2,"shiftDay":2,"shiftStartTime":1,"shiftEndTime":500,"shiftType":1,"shiftPosition":null,"shiftDepartment":"Staff"},
  {"shiftId":999,"userId":2,"shiftDay":3,"shiftStartTime":300,"shiftEndTime":400,"shiftType":1,"shiftPosition":"Associate","shiftDepartment":"Counter"},
  {"shiftId":10,"userId":2,"shiftDay":5,"shiftStartTime":1,"shiftEndTime":300,"shiftType":1,"shiftPosition":"Associate","shiftDepartment":"Counter"},
  {"shiftId":11,"userId":3,"shiftDay":2,"shiftStartTime":1,"shiftEndTime":200,"shiftType":1,"shiftPosition":"Kook","shiftDepartment":"Kirtchan"}
]
