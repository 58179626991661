import React from "react";
import { Redirect } from "react-router-dom";
import SignUpForm from "./SignUpForm";
import styles from "./index.module.css";
import userContext from "../../context/userContext";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToHome: false
    }

    this.triggerRedirect = () => this.setState({ redirectToHome: true})
  }

  render() {
    if (this.state.redirectToHome) return <Redirect to="/" />

    return (
      <userContext.Consumer>
        {
          ({ logIn }) => {
            return (
              <div className={styles.container}>
                <SignUpForm logIn={logIn}
                            triggerRedirect={this.triggerRedirect}
                />
              </div>
            )
          }
        }
      </userContext.Consumer>
    )
  }
}

export default SignUp;
