import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import stockPhoto from "../../img/laptop-from-above.jpg";

class Home extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <section className={styles.sectionContainer}>
          <h1 className={styles.h1}>Simplify how you create work schedules</h1>
          <div className={styles.lineFadedRight} />
          <article className={styles.articleContainer}>
            <img src={stockPhoto} alt="making-a-budget-tracking-finances.jpg"
                 className={styles.img}
            />
            <div className={styles.textBlock}>
              <p className={styles.text}>
                Manage, organize, and <span className={styles.grnText}>optimize</span> your employees' work schedules.
              </p>
            </div>
          </article>
          <div className={styles.lineFadedLeft} />
          <div className={styles.linksContainer}>
            <a href={"#"} className={styles.link}>How it works</a>
            <Link to={"/login"} className={styles.link}>Get started</Link>
          </div>
        </section>
      </div>
    )
  }
}

export default Home;
