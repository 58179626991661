import React from "react";
import { withRouter } from "react-router-dom";
// Components
import Nav from "./Nav";
// Images
import stackIcon from "../../img/stack-icon.png";
import cancelIcon from "../../img/x-icon.png";
import logoIcon from "../../img/tk-icon.png";
// Styles
import styles from "./index.module.css";
import userContext from "../../context/userContext";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavVisible: false,
    }

    this.handleNavClick = () => this.setState(prevState => {
      return {isNavVisible: !prevState.isNavVisible}
    });

    this.handleNavLinkClick = () => this.setState({isNavVisible: false})

    this.determineHeading = () => {
      switch (this.props.history.location.pathname) {
        case "/":
          return "Time Keeper"
        case "/employees":
          return "Employees"
        case "/schedule":
          return "Schedule"
        default:
          return "Time Keeper"
      }
    }
  }

  render() {
    return (
      <userContext.Consumer>
        {
          ({ isLoggedIn, logOut }) => {
            return (
              <header className={styles.container}>
                <img src={logoIcon} alt="site-logo" className={styles.logo} />
                <h1 className={styles.heading}>{this.determineHeading()}</h1>
                <div className={styles.navContainer}>
                  {
                    this.state.isNavVisible ?
                      <button onClick={this.handleNavClick} className={styles.navButton}>
                        <img src={cancelIcon} alt="cancel-icon" style={{width: "36px"}}/>
                      </button>
                      :
                      <button onClick={this.handleNavClick} className={styles.navButton}>
                        <img src={stackIcon} alt="stack-icon" style={{width: "36px"}}/>
                      </button>
                  }
                </div>
                {
                  this.state.isNavVisible &&
                  <Nav handleNavLinkClick={this.handleNavLinkClick}
                       isLoggedIn={isLoggedIn}
                       logOut={() => {
                         logOut();
                         this.handleNavLinkClick();
                       }}
                  />
                }
              </header>
            )
          }
        }
      </userContext.Consumer>
    )
  }
}

export default withRouter(Header);
