import React from "react";
import styles from "./Shift.module.css";
import sharedStyles from "../Shared/Form.module.css";
import downArrowIcon from "../../img/down-arrow-icon.png";
import { Util } from "../../util";

class Shift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpandedView: false,
    };

    this.handleExpandClick = () => this.setState(prevState => {
      return { isExpandedView: !prevState.isExpandedView}
    })
  }

  render() {
    const { shift, shiftState } = this.props;

    const [ startTime, startPeriod ] = Util.convertMinutesToDisplayTime(shift.shiftStartTime);
    const [ endTime, endPeriod ] = Util.convertMinutesToDisplayTime(shift.shiftEndTime);

    return (
      <div className={styles.container}>

        <div className={styles.initialViewContainer}>
          <div className={styles.timeContainer}>
            {startTime}<sub>{startPeriod}</sub>
            <span>&nbsp;-&nbsp;</span>
            {endTime}<sub>{endPeriod}</sub>
          </div>

          <select onChange={this.props.updateShiftState(shift)}
                  className={styles.selectInput}
                  defaultValue={shiftState.selectedEmployeeId}
          >
            <option value="">- Select Employee -</option>
            {
              Object.keys(shiftState.applicableEmployees)
                .map(key =>
                  <option key={key}
                          value={shiftState.applicableEmployees[key].employeeId}
                  >
                    {shiftState.applicableEmployees[key].employeeName}
                  </option>
                )
            }
          </select>

          <button className={styles.expandButton} onClick={this.handleExpandClick}>
            {
              this.state.isExpandedView ?
                <img src={downArrowIcon} alt="down-arrow-icon" className={styles.iconUpsideDown} />
                :
                <img src={downArrowIcon} alt="down-arrow-icon" className={styles.icon} />
            }
          </button>
        </div>

        {
          this.state.isExpandedView &&
            <div className={styles.expandedViewContainer}>
              <h4 className={styles.smHeading}><i>Shift Information</i></h4>
              <hr />
              <div className={styles.textContainer}>
                <div className={styles.textRow}>
                  <span>Position:&nbsp;</span>
                  {shift.shiftPosition}
                </div>

                <div className={styles.textRow}>
                  <span>Department:&nbsp;</span>
                  {shift.shiftDepartment}
                </div>

                <div className={styles.textRow}>
                  <span>Duration:&nbsp;</span>
                  {Util.displayDuration(shift.shiftEndTime - shift.shiftStartTime)}
                </div>
              </div>

              <div className={styles.buttonRow}>
                <button className={sharedStyles.buttonOrange}
                        onClick={this.props.deleteShift(this.props.shift)}
                >
                  Delete Shift
                </button>
              </div>
            </div>
        }

      </div>
    )
  }
}

export default Shift;
