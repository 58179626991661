import React from "react";
import ReactLoading from "react-loading";
import styles from "../Shared/Form.module.css";
import { Util } from "../../util";
// Data mocks
import { Days } from "../../mocks/daysOfWeek";
import TimePicker from "react-time-picker";

class UpdateAvailabilityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormVisible: false,
      isSubmitting: false
    };

    this.handleClick = e => {
      e.preventDefault();
      this.setState(prevState => {
        return { isFormVisible: !prevState.isFormVisible}
      });
    }

    this.submitForm = () => {
      const { availability } = this.props;
      let promises = [];

      for (const key of Object.keys(availability)) {
        let res = fetch("https://burgertechnologies.com/timekeeper/availability/update.php", {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(availability[key])
        })
          .then(res => res.json())
          .then(res => res.result)

        promises.push(res);
      }

      Promise.all(promises)
        .then(results => {
          if (results.every(bool => bool === true)) {
            this.setState( { isSubmitting: false})
          }
        })
        .catch(error => console.log(error))
    }

    this.handleSubmit = e => {
      e.preventDefault();

      this.setState({ isSubmitting: true}, () => this.submitForm())
    }

    this.sortAvailabilityByDay = availability => {
      const array = Object.keys(availability).map(key => availability[key])

      return array.sort((a, b) => a.availabilityDay - b.availabilityDay)
    }
  }

  render() {
    const { availability } = this.props;

    return (
      <div className={styles.container}>
        {
          this.state.isFormVisible &&
            <div className={styles.wrapper}>
              {
                this.state.isSubmitting ?
                  <ReactLoading type={"bars"} color={"black"} />
                  :
                  <form onSubmit={this.handleSubmit} className={styles.formContainer}>
                    <h2 className={styles.formHeading}>{this.props.name}'s availability</h2>
                    <hr/>
                    {
                      this.sortAvailabilityByDay(availability).map(avail => {
                        return <TimeFieldset key={avail.availabilityId}
                                             day={Days[avail.availabilityDay]}
                                             employeeId={this.props.employeeId}
                                             availabilityId={avail.availabilityId}
                                             startTime={avail.availabilityStartTime}
                                             endTime={avail.availabilityEndTime}
                                             updateAvailability={this.props.updateAvailability}
                        />
                      })

                    }
                    <input type="submit"
                           value="Confirm"
                           className={styles.buttonGreen}
                    />
                    <button onClick={this.handleClick} className={styles.buttonOrange}>Cancel</button>
                  </form>
              }
            </div>
        }
        <button onClick={this.handleClick}
                className={styles.buttonGreen}
        >
          Update availability
        </button>
      </div>
    )
  }
}

function TimeFieldset(props) {
  return (
    <div style={{width: "100%"}}>
      <label className={styles.timeInputLabel}>{Util.capitalizeString(props.day)}</label>
      <div className={styles.timeFieldset}>
        <div className={styles.timeInputContainerFrom}>
          <label htmlFor={`${props.day}StartTime`} className={styles.timeLabel}>From:</label>
          <TimePicker value={Util.convertMinutesToTimeString(props.startTime)}
                      onChange={props.updateAvailability(props.employeeId)(props.availabilityId)("availabilityStartTime")}
                      clearIcon={null}
                      disableClock={true}
          />
        </div>
        <div className={styles.timeInputContainerTo}>
          <label htmlFor={`${props.day}EndTime`} className={styles.timeLabel}>To:</label>
          <TimePicker value={Util.convertMinutesToTimeString(props.endTime)}
                      onChange={props.updateAvailability(props.employeeId)(props.availabilityId)("availabilityEndTime")}
                      clearIcon={null}
                      disableClock={true}
          />
        </div>
      </div>
    </div>
  )
}

export default UpdateAvailabilityForm;
