// Dependencies
import React from "react";
import ReactLoading from "react-loading";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// Components
import Day from "./Day";
import EditorNav from "./EditorNav";
import Schedule from "./Schedule";
import TextButton from "../Shared/TextButton";
import Overview from "./Overview";
// Styles
import styles from "./index.module.css";
//Utility
import { Util } from "../../util";
// Context
import userContext from "../../context/userContext";
// Data mocks
import { Days } from "../../mocks/daysOfWeek";
import { employeeSampleResponse } from "../../mocks/employeesSampleResponse";
import { availabilitySampleResponse } from "../../mocks/availabilitySampleResponse";
import { shiftsSampleResponse } from "../../mocks/shiftsSampleResponse";

class ScheduleEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allEmployees: {},
      allShifts: [],
      allShiftStates: {
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {}
      },
      errors: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: []
      },
      sortedShiftsByDay: {
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {}
      },
      sortedEmployeesByDayAvail: {
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {}
      },
      currentDay: 1, // number 1 - 7, controls editor flow
      isFetching: true,
      isOverviewVisible: false,
      renderSchedule: false,
    };

    // Shift operations
    /**
     * Set up all shift states with data fetched from backend.
     * @param shifts
     * @param employees
     * @return {{sunday: {}, saturday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, monday: {}}}
     */
    this.setAllShiftStates = (shifts, employees) => {
      let allShiftStates = {
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {}
      }

      // loop over all days of schedule
      for (const day of Object.keys(allShiftStates)) {
        let shiftStates = {};

        // set state for all shift nodes
        for (const shiftId of Object.keys(shifts[day])) {
          // Get which employees can work the shift
          const employeesForShift = Util.determineEmployeesForShift(shifts[day][shiftId], employees[day]);
          const duration = shifts[day][shiftId].shiftEndTime - shifts[day][shiftId].shiftStartTime;

          // initialize node state object if not exist
          if (!shiftStates.hasOwnProperty(shifts[day][shiftId].shiftId)) {
            shiftStates[shifts[day][shiftId].shiftId] = {
              status: "empty",
              isSelected: false,
              message: "",
              selectedEmployeeId: "",
              applicableEmployees: employeesForShift,
              duration: duration
            };
          }
        }

        allShiftStates[day] = {
          ...shiftStates,
          errors: [], // Don't forget to set the init errors array!
        }
      }

      return allShiftStates
    }

    /**
     * Add a new shift to states
     * @param {Object} shift
     */
    this.addNewShift = shift => this.setState(prevState => {
      const newShiftState = {
        status: "empty",
        isSelected: false,
        message: "",
        selectedEmployeeId: "",
        applicableEmployees: Util.determineEmployeesForShift(shift, this.state.sortedEmployeesByDayAvail[Days[shift.shiftDay]]),
        duration: shift.shiftEndTime - shift.shiftStartTime
      };

      return {
        allShifts: [
          ...prevState.allShifts,
          shift
        ],
        allShiftStates: {
          ...prevState.allShiftStates,
          [Days[shift.shiftDay]]: {
            ...prevState.allShiftStates[Days[shift.shiftDay]],
            [shift.shiftId]: newShiftState,
          }
        },
        sortedShiftsByDay: {
          ...prevState.sortedShiftsByDay,
          [Days[shift.shiftDay]]: {
            ...prevState.sortedShiftsByDay[Days[shift.shiftDay]],
            [shift.shiftId]: shift
          }
        },
      }
    });

    /**
     * Update a selected shift's state.
     * @param {Object} shift
     * @return {function(...[*]=)}
     */
    this.updateShiftState = shift => e => {
      // initialize shift state if none selected
      if (e.target.value === "") this.setState(prevState => {
        return {
          allShiftStates: {
            ...prevState.allShiftStates,
            [Days[shift.shiftDay]]: {
              ...prevState.allShiftStates[Days[shift.shiftDay]],
              [shift.shiftId]: {
                ...prevState.allShiftStates[Days[shift.shiftDay]][shift.shiftId],
                status: "empty",
                isSelected: false,
                selectedEmployeeId: ""
              }
            }
          }
        }
      });
      // Update shift state with selected employee id
      else this.setState(prevState => {
        return {
          allShiftStates: {
            ...prevState.allShiftStates,
            [Days[shift.shiftDay]]: {
              ...prevState.allShiftStates[Days[shift.shiftDay]],
              [shift.shiftId]: {
                ...prevState.allShiftStates[Days[shift.shiftDay]][shift.shiftId],
                status: "ok",
                isSelected: true,
                selectedEmployeeId: e.target.value
              }
            }
          }
        }
      }, () => this.countSelectedEmployees());
    }

    /**
     *
     * @param shift
     * @return {function(...[*]=)}
     */
    this.deleteShift = shift => e => {
      e.preventDefault();

      fetch("https://burgertechnologies.com/timekeeper/shifts/delete.php", {
        method: "DELETE",
        body: JSON.stringify({ id: shift.shiftId})
      })
        .then(res => res.json())
        .then(res => {
          if (res.result) {
            this.setState(prevState => {
              // remove shift from allShifts
              let newAllShifts = [...prevState.allShifts];
              for (let i = 0; i < newAllShifts.length; i++) {
                if (newAllShifts[i].shiftId === shift.shiftId) newAllShifts.splice(i, 1);
              }

              // remove shift from allShiftStates
              let newAllShiftStates = Object.assign({}, prevState.allShiftStates);
              delete newAllShiftStates[Days[shift.shiftDay]][shift.shiftId];

              // remove shift from sortedShiftsByDay
              let newSortedShiftsByDay = Object.assign({}, prevState.sortedShiftsByDay);
              delete newSortedShiftsByDay[Days[shift.shiftDay]][shift.shiftId];

              return {
                allShifts: newAllShifts,
                allShiftStates: newAllShiftStates,
                sortedShiftsByDay: newSortedShiftsByDay
              }
            })
          }
        })
        .catch(error => console.log(error));
    }

    // Data handling
    /**
     * Creates new object with each employee grouped with the corresponding shift from the editor.
     * @return {{}}
     */
    this.groupEmployeesWithSelectedShifts = () => {
      let { allEmployees, allShiftStates, sortedShiftsByDay } = this.state;
      let employees = {};

      // Loop over days
      for (const day of Object.keys(allShiftStates)) {

        // Loop over shifts in current day
        for (const shiftId of Object.keys(allShiftStates[day])) {
          // Ignore errors array
          if (shiftId.localeCompare("errors") === -1) {
            const employeeId = allShiftStates[day][shiftId].selectedEmployeeId;

            // Ignore empty string ids. Empty string/undefined === none selected
            if (employeeId !== undefined && employeeId.length > 0) {

              // Already tracking employee
              if (employees.hasOwnProperty(employeeId)) {
                // Add start/end times for current day
                employees[employeeId]["shifts"][day] = {
                  startTime: sortedShiftsByDay[day][shiftId].shiftStartTime,
                  endTime: sortedShiftsByDay[day][shiftId].shiftEndTime,
                  duration: allShiftStates[day][shiftId].duration
                }
              }
              else {
                // Init employee then add shift start/end times
                employees[employeeId] = {
                  name: allEmployees[employeeId].employeeName,
                  shifts: {
                    monday: {
                      startTime: null,
                      endTime: null
                    },
                    tuesday: {
                      startTime: null,
                      endTime: null
                    },
                    wednesday: {
                      startTime: null,
                      endTime: null
                    },
                    thursday: {
                      startTime: null,
                      endTime: null
                    },
                    friday: {
                      startTime: null,
                      endTime: null
                    },
                    saturday: {
                      startTime: null,
                      endTime: null
                    },
                    sunday: {
                      startTime: null,
                      endTime: null
                    }
                  }
                }
                employees[employeeId]["shifts"][day] = {
                  startTime: sortedShiftsByDay[day][shiftId].shiftStartTime,
                  endTime: sortedShiftsByDay[day][shiftId].shiftEndTime,
                  duration: allShiftStates[day][shiftId].duration
                }
              }
            }
          }
        }
      }

      return employees
    }

    // Shift state validation
    /**
     * Count all selected employees for each node. Part of validation.
     */
    this.countSelectedEmployees = () => {
      let employeeSelectCount = {
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {}
      };

      // Loop over days of all shift states
      for (const day of Object.keys(this.state.allShiftStates)) {

        // Loop over all states for day
        for (const shiftId of Object.keys(this.state.allShiftStates[day])) {
          const shiftState = this.state.allShiftStates[day][shiftId];

          // Gate for empty string values for id
          if (shiftState.isSelected) {
            // increment count if present
            if (employeeSelectCount[day].hasOwnProperty(shiftState.selectedEmployeeId)) {
              employeeSelectCount[day][shiftState.selectedEmployeeId]++;
            }
            // if count not present, start a count
            else employeeSelectCount[day][shiftState.selectedEmployeeId] = 1;
          }
        }
      }

      // determine error messages
      let errors = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: []
      };

      // loop over employee select counts
      for (const day of Object.keys(employeeSelectCount)) {
        // loop over days
        for (const employeeId of Object.keys(employeeSelectCount[day])) {
          // current employee has been selected in multiple shifts
          if (employeeSelectCount[day][employeeId] > 1) {
            errors[day].push(`${this.state.allEmployees[employeeId].employeeName} is scheduled ${employeeSelectCount[day][employeeId]} times today.`)
          }
        }
      }

      // set errors in state
      this.setState({ errors: errors});
    }

    // Navigate between days in the schedule
    this.navigateNextDay = e => {
      e.preventDefault();
      this.setState(prevState => {
        let day = prevState.currentDay + 1;
        if (day > 7) day = 1
        return {
          currentDay: day
        }
      })
    }

    this.navigatePreviousDay = e => {
      e.preventDefault();
      this.setState(prevState => {
        let day = prevState.currentDay - 1;
        if (day < 1) day = 7
        return {
          currentDay: day
        }
      })
    }

    // Toolbar functions
    /**
     * Create PDF doc from StudySheet component, then download to client.
     * @param {number} pages - number of pages of PDF doc.
     */
    this.downloadPdf = async pages => {
      const pdf = new jsPDF({
        orientation: "l",
        format: [3300, 2550], // width and height for letter sized paper in pixels, landscape orientation
        unit: "px",
        hotfixes: ["px_scaling"],
      });

      for (let i = 0; i < pages; i++) {
        // Select current study sheet component
        const canvas = await html2canvas(document.querySelector(`#pdf-${i}`));

        // Insert new page after default page
        if (i !== 0) pdf.insertPage(i);

        // Create image data and add to pdf document
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'JPEG', 0, 0, 3300, 2550, `page-${i}`, "SLOW");

        // Download only on last iteration
        if (i === pages - 1) pdf.save("schedule.pdf");
      }

      this.setState({renderSchedule: false});
    }

    // Toolbar Click Handlers
    this.handleDownloadClick = () => {
      this.setState({renderSchedule: true}, () => {
        this.downloadPdf(1)
          .catch(error => console.log(error));
      });
    }

    this.handleShowOverviewClick = () => {
      this.setState(prevState => {
        return {
          isOverviewVisible: !prevState.isOverviewVisible
        }
      });
    }

    // Fetch requests
    this.fetchEmployees = () => fetch("https://burgertechnologies.com/timekeeper/employees/read.php")
      .then(res => employeeSampleResponse) // DEV PARAMETER HERE! -> res.json()
      .catch(err => console.log(err))

    this.fetchAvailability = () => fetch("https://burgertechnologies.com/timekeeper/availability/read.php")
      .then(res => availabilitySampleResponse) // DEV PARAMETER HERE! -> res.json()
      .catch(err => console.log(err))

    this.fetchShifts = () => fetch("https://burgertechnologies.com/timekeeper/shifts/read.php")
      .then(res => shiftsSampleResponse) // DEV PARAMETER HERE! -> res.json()
      .catch(err => console.log(err))
  }

  componentDidMount() {
    Promise.all([this.fetchEmployees(), this.fetchAvailability(), this.fetchShifts()])
      .then(([employees, availability, shifts]) => {
        const groupedEmployees = Util.groupEmployeeWithAvailability(employees, availability);
        const sortedEmployeesByDayAvail = Util.sortEmployeesByDayAvail(groupedEmployees);
        const sortedShiftsByDay = Util.sortShiftsByDay(shifts);
        const allShiftStates = this.setAllShiftStates(sortedShiftsByDay, sortedEmployeesByDayAvail);

        this.setState({
          allEmployees: groupedEmployees,
          allShifts: shifts,
          allShiftStates: allShiftStates,
          sortedShiftsByDay: sortedShiftsByDay,
          sortedEmployeesByDayAvail: sortedEmployeesByDayAvail
        });
      })
      .then(() => this.setState({ isFetching: false}))
      .catch(err => console.log(err))
  }

  render() {
    const currentDay = Days[this.state.currentDay];

    return (
      <userContext.Consumer>
        {
          ({ isLoggedIn }) => {
            return (
              <div className={styles.container}>
                {
                  this.state.isFetching ?
                    <div className={styles.loadingContainer}>
                      <ReactLoading type={"bars"} color={"black"} />
                    </div>
                    :
                    !isLoggedIn ? // DEV SETTING! GET RID OF ! WHEN USER ACCOUNTS ARE DONE
                      <div className={styles.editorWindow}>

                        <EditorNav day={currentDay}
                                   navigateNextDay={this.navigateNextDay}
                                   navigatePreviousDay={this.navigatePreviousDay}
                        />

                        <Day day={this.state.currentDay}
                             shifts={this.state.sortedShiftsByDay[currentDay]}
                             employees={this.state.sortedEmployeesByDayAvail[currentDay]}
                             allShiftStates={this.state.allShiftStates[currentDay]}
                             addNewShift={this.addNewShift}
                             updateShiftState={this.updateShiftState}
                             deleteShift={this.deleteShift}
                             errors={this.state.errors[currentDay]}
                        />

                        {
                          this.state.renderSchedule &&
                            <Schedule employees={this.groupEmployeesWithSelectedShifts()}/>
                        }

                        {
                          this.state.isOverviewVisible &&
                            <Overview closeOverview={this.handleShowOverviewClick}
                                      employees={this.groupEmployeesWithSelectedShifts()}
                                      allShifts={this.state.allShifts}
                                      handleClick={this.handleShowOverviewClick}
                            />
                        }

                        <footer className={styles.footer}>
                          <TextButton onClick={this.handleShowOverviewClick}
                                      color={"darkgreen"}
                          >
                            {this.state.isOverviewVisible ? "Hide" : "Show"} Overview
                          </TextButton>
                          <TextButton onClick={this.handleDownloadClick}
                                      color={"darkblue"}
                          >
                            Download PDF
                          </TextButton>
                        </footer>

                      </div>
                      :
                      ""
                }
              </div>
            )
          }
        }
      </userContext.Consumer>
    )
  }
}

export default ScheduleEditor;
